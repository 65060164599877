import React from "react";
import '../src/style.css'
import { useState, useEffect } from 'react';
import { useAccount, usePrepareContractWrite, useContractWrite, useContractReads, useWaitForTransaction, useEnsName } from 'wagmi';
import contractInterface from "../src/config/abi.json";
import {
    useConnectModal,
    useAccountModal
} from '@rainbow-me/rainbowkit';
import { useDebounce } from 'use-debounce';
import Popup from '../src/components/popup'
import $ from "jquery";

function App() {
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const [ChainError, setChainError] = useState(false);
    const [MaxMint, setMaxMint] = useState(false);
    const [UserRejected, setUserRejected] = useState(false);
    const [NotFUNDS, setNotFUNDS] = useState(false);
    const [OnSuccessPopup, setOnSuccessPopup] = useState(false);
    const [DataLoading, setDataLoading] = useState(true);
    const links = {
        Twitter: "https://twitter.com/RecursiveCats",
        Etherscan: "https://etherscan.io/address/0x3170338b7086b906d33a9d7f3b2ea04a11513e26",
        OpenSea: "https://opensea.io/collection/recats",
        TG: "https://t.me/recats",
        UNISWAP: "https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x3170338b7086B906d33a9D7F3b2ea04a11513E26",
    }
    const { isConnected, isDisconnected, isConnecting, address, connector: activeConnector } = useAccount();
    const ensName = useEnsName({
        address: address,
        onSuccess(data) {
            console.log('Success', data)
        },
        onError(error) {
            console.log('ensName', error)
        },
    })
    const toFix = 1;
    const funcName = ['TOTAL_MINT_SUPPLY', 'USER_MINT_SUPPLY', 'NFT_PRICE', 'totalSupply', 'mintCount'];
    const CONTRACT_ADDRESS = "0x3170338b7086b906d33a9d7f3b2ea04a11513e26"

    const [isContract, setisContract] = useState({
        TOTAL_MINT_SUPPLY: 1000,
        USER_MINT_SUPPLY: 10,
        NFT_PRICE: "5500000000000000",
        totalSupply: 0,
        mintCount: 0,
    });

    // const [inputValue, setinputValue] = useState(1);
    // const [debounceValue] = useDebounce(inputValue, 500);

    const [Errors, setErrors] = useState({
        INSUFFICIENT_FUNDS: false,
        UNPREDICTABLE_GAS_LIMIT: false,
    })

    useEffect(() => {
        const handleConnectorUpdate = ({ account, chain }) => {
            if (account) {
                console.log('new account', account)
                setDataLoading(true)
            } else if (chain) {
                if (chain.id !== 1) {
                    setChainError(true)
                }
                console.log('new chain', chain)
            }
        }

        if (activeConnector) {
            activeConnector.on('change', handleConnectorUpdate)
        }
    }, [activeConnector])

    const { config: configpbmint, isError: MintError } = usePrepareContractWrite({
        address: CONTRACT_ADDRESS,
        abi: contractInterface,
        functionName: 'mint',
        // args: [debounceValue],
        overrides: {
            value: Number(isContract.NFT_PRICE).toString(),
        },
        onError(error) {
            if (error.code === 'INSUFFICIENT_FUNDS') {
                setErrors({ ...Errors, "INSUFFICIENT_FUNDS": true })
            }
            if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
                setErrors({ ...Errors, "UNPREDICTABLE_GAS_LIMIT": true })
            }
            console.log('Error', error.name + " " + error.code)
        },
    })

    const { data: mintData, writeAsync: mint } = useContractWrite({
        ...configpbmint,
        onError(error) {
            if (error.name === 'UserRejectedRequestError') {
                setUserRejected(true)
                setNotFUNDS(false)
            }
            console.log('Error', error.name + " " + error.code)
        },
    })

    const { isLoading: mintLoading } = useWaitForTransaction({
        hash: mintData?.hash,
        onSuccess(data) {
            setOnSuccessPopup(true)
        },
        onError(error) {
            console.log(error.name + " " + error.code)
        },
    })

    useContractReads({
        contracts: [
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[0],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[1],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[2],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[3],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[4],
                args: [address],
            },
        ],
        watch: true,
        cacheTime: 10000,
        onError(error) {
            console.log('Error', error)
        },
        onSuccess(data) {
            if (isDisconnected) {
                console.log("Disconnected")
            } else {
                console.log('Success', data?.toString())
                let result = {}
                funcName.map((el, i) => {
                    if (data[i] === null) {
                        result[el] = isContract[el.toString()]
                        return true
                    } else {
                        result[el] = data[i]?.toString()
                        return true
                    }
                })
                setisContract(result);
            }
            setDataLoading(false);
        },
    })


    // const handleInputChange = (event) => {
    //     let enteredValue = event.target.value;

    //     enteredValue = enteredValue.slice(0, 2);
    //     enteredValue = enteredValue.replace('-', '');
    //     enteredValue = enteredValue.replace('+', '');

    //     if ((!isNaN(enteredValue) && Number.isInteger(parseFloat(enteredValue))) || enteredValue === '') {
    //         setinputValue(enteredValue);
    //     }
    // };


    const clicker = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
            $('.main-soc').removeClass('active');
            $('body').removeClass('lock');
        }
    }
    const burger = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
            $('.main-soc').removeClass('active');
            $('body').removeClass('lock');
        } else {
            $('.header__burger').addClass('clicked');
            $('.header__burger').addClass('active');
            $('.mob-menu').addClass('active');
            $('.header-menu').addClass('active');
            $('.main-soc').addClass('active');
            $('body').addClass('lock');
        }
    }


    return (
        <>
            <div className="wbackground">
                <div className="nav">
                    <div className="nav-menu">
                        <div className="main-logo">

                            <div>
                                <img src="img/logo.svg" alt="logo" />
                            </div>

                            <div class="main-logo__text">
                                <a href="#mint">MINT</a>
                                <a href="#about">ERC404</a>
                                <a href="#rarity">RARITY</a>
                            </div>
                        </div>
                        <div className="menu">
                            <ul className="soc">
                                <li className="btn-hover">
                                    <a href={links.TG} target="_blank" rel="noreferrer"><img src="img/tg.svg" alt="TG" /></a>
                                </li>
                                <li className="btn-hover">
                                    <a href={links.Etherscan} target="_blank" rel="noreferrer"><img src="img/eth.svg" alt="Etherscan" /></a>
                                </li>
                                <li className="btn-hover">
                                    <a href={links.Twitter} target="_blank" rel="noreferrer"><img src="img/x.svg" alt="X" /></a>
                                </li>
                                <li className="btn-hover">
                                    <a href={links.OpenSea} target="_blank" rel="noreferrer">
                                        <img src="img/op.svg" alt="Opensea" />
                                    </a>
                                </li>
                            </ul>
                            <div className="connect btn-hover">
                                {
                                    isConnecting ? [
                                        <>
                                            <p className="unselectable">Connecting...</p>
                                        </>
                                    ] : [
                                        DataLoading ? [
                                            <>
                                                <p className="unselectable">Loading...</p>
                                            </>
                                        ] : [
                                            <>
                                                {
                                                    isConnected && (
                                                        <>
                                                            {ensName.data != null ? [
                                                                <>
                                                                    <img src="img/green.svg" alt="Green" />
                                                                    <p className="unselectable" onClick={openAccountModal}>{ensName.data}</p>
                                                                </>
                                                            ] : [
                                                                <>
                                                                    <img src="img/green.svg" alt="Green" />
                                                                    <p className="unselectable" onClick={openAccountModal}>{address.substr(0, 2) + '...' + address.substr(-4, 4)}</p>
                                                                </>
                                                            ]}
                                                        </>
                                                    )
                                                }
                                                {
                                                    isDisconnected && (
                                                        <>
                                                            <p className="unselectable" onClick={openConnectModal}>Connect</p>
                                                        </>
                                                    )
                                                }
                                            </>
                                        ]
                                    ]

                                }
                            </div>
                        </div>
                    </div>
                    <div className="mob-menu">
                        <div className="mob-menu__items" onClick={() => clicker()}>
                            {
                                isConnected && (
                                    <>
                                        {ensName.data != null ? [
                                            <>
                                                <div className="header-menu__item wallet">
                                                    <p className="unselectable" onClick={openAccountModal}>{ensName.data}</p>
                                                </div>
                                            </>
                                        ] : [
                                            <>
                                                <div className="header-menu__item wallet">
                                                    <p className="unselectable" onClick={openAccountModal}>{address.substr(0, 2) + '...' + address.substr(-4, 4)}</p>
                                                </div>
                                            </>
                                        ]}
                                    </>
                                )
                            }
                            {
                                isDisconnected && (
                                    <>
                                        <div className="header-menu__item wallet">
                                            <p className="unselectable" onClick={openConnectModal}>Connect</p>
                                        </div>
                                    </>
                                )
                            }
                            <div className="header-menu__item">
                                <a href="#mint">MINT</a>
                            </div>
                            <div className="header-menu__item">
                                <a href="#about">ERC404</a>
                            </div>
                            <div className="header-menu__item">
                                <a href="#rarity">RARITY</a>
                            </div>
                            <div className="header-soc">
                                <a href={links.TG} target="_blank" rel="noreferrer">
                                    <img src="img/tg.svg" alt="TG" />
                                </a>
                                <a href={links.Etherscan} target="_blank" rel="noreferrer">
                                    <img src="img/eth.svg" alt="Etherscan Contract" />
                                </a>
                                <a href={links.Twitter} target="_blank" rel="noreferrer">
                                    <img src="/img/x.svg" alt="Twitter" />
                                </a>
                                <a href={links.OpenSea} target="_blank" rel="noreferrer">
                                    <img src="/img/op.svg" alt="Opensea collection" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="header__burger" id="buger" onClick={() => burger()}>
                        <span></span>
                    </div>
                </div>
                <div class="erc404">
                    <div class="erc404-info">
                        <div class="erc404-info__text">
                            <p>ENABLED BY</p>
                            <p style={{ color: "#C6FF25", fontStyle: "italic" }}>INVERTED ERC404,</p>
                            <p>THE MOST EXPERIMENTAL STANDARD IN EXISTENCE</p>
                        </div>
                        <div class="erc404-info__about">
                            <p>100 RECURSIVE CATS DRAWN IN ASCII STYLE CAN BE MINTED AS NORMAL NFTs, BOUGHT AS TOKENS ON UNISWAP, OR PURCHASED AS NFT ON ANY NFT MARKETPLACE</p>
                        </div>
                        <div class="erc404-info__btn">
                            <div class="mint-btn"><a href="#mint">MINT A CAT</a></div>
                            <div class="btn-erc btn-hover"><a href="#about">WHAT IS INVERTED ERC404</a></div>
                        </div>
                    </div>
                    <div class="erc404-img">
                        <img src="img/cat.svg" alt="Cat" />
                    </div>
                </div>
                <div class="mint-box">
                    <div class="topline"></div>
                    <div class="mint-box-bg">
                        <div class="mint" id="mint">
                            <div class="mint-img">
                                <img src="img/mint.svg" alt="Mint" />
                            </div>
                            {/* <div class="counter">
                                <input type="text" placeholder="ENTER AMOUNT (MAX. 10)" value={inputValue} onChange={handleInputChange} />
                            </div> */}
                            {
                                DataLoading ? [
                                    <div className="mint-btn unselectable">
                                        <p className="unselectable">Loading...</p>
                                    </div>
                                ] : [
                                    isConnecting ? [
                                        <div className="mint-btn unselectable">
                                            Connecting...
                                        </div>
                                    ] : [
                                        isConnected ? [
                                            isContract.CONTRACT_STATUS === "0" ? [
                                                <div className="mint-btn unselectable">
                                                    Sale inactive
                                                </div>
                                            ] : [
                                                <div className="mint-btn unselectable" onClick={() => {
                                                    if (MintError) {
                                                        setNotFUNDS(Errors.INSUFFICIENT_FUNDS)
                                                        setMaxMint(Errors.UNPREDICTABLE_GAS_LIMIT)
                                                    } else {
                                                        !mintLoading ? mint() : console.log("Transaction in progress")
                                                    }
                                                }

                                                }>
                                                    {
                                                        mintLoading ? [
                                                            <>
                                                                <p className="unselectable">Minting...</p>
                                                            </>
                                                        ] : [
                                                            <>
                                                                <p className="unselectable">
                                                                    {
                                                                        "MINT CAT FOR " + (Number(isContract.NFT_PRICE / 1000000000000000000) * 1).toFixed(toFix) + " ETH"
                                                                    }
                                                                </p>
                                                            </>
                                                        ]
                                                    }
                                                </div>
                                            ]
                                        ] : [
                                            <div className="mint-btn unselectable" onClick={openConnectModal}>
                                                CONNECT WALLET
                                            </div>
                                        ]
                                    ]
                                ]
                            }
                            <div class="mint-links">
                                <div class="mint-links__img">
                                    <img src="img/or.svg" alt="OR" />
                                </div>
                                <div class="mint-links__btns">
                                    <div class="uniswap"><a href={links.UNISWAP}>BUY <span>$RECAT</span> ON UNISWAP</a></div>
                                    <div class="opensea"><a href={links.OpenSea}>BUY <span>$RECAT</span> ON OPENSEA</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btline"></div>
                </div>
            </div>
            <div class="about" id="about">
                <div class="cat2">
                    <img src="img/cat2.svg" alt="Cat2" />
                </div>
                <div class="about-title">
                    WHAT IS INVERTED ERC404?
                </div>
                <div class="section">
                    INTRO
                </div>
                <div class="about-text">
                    We are currently at a pivotal point where the destiny of future NFT collections may be shaped, thanks to the introduction of the new ERC-404 standard. This standard, a blend of ERC-20 and ERC-721, facilitates NFT trading on decentralized exchanges, addressing liquidity challenges within the NFT space. Comprehensive explanations of this standard are readily available online, and it's assumed that you are well-versed in the advantages and drawbacks of this solution. Additionally, various forks have emerged, including ERC-20721, which we anticipate could become the next standard if scalability and user complexity concerns are resolved. Until that happens, ERC-404 remains the primary candidate for a standardized solution for all future NFT collections.
                </div>
                <div class="section">WHAT’S NEW?</div>
                <div class="about-text">
                    Despite its innovative nature, ERC-404, positioned between an Fungible and non-fungible standard, tends towards conventional ERC-20 tokens. Minting an NFT in this standard deviates from the regular collection process; instead, one must first purchase the corresponding token. Only upon acquiring a complete token quantity (1, 2, 0.8 + 0.2, etc.) will the associated NFT be minted to the wallet. <span>Inverted ERC-404,</span> in conjunction with the existing token purchase method, <p>allows for the minting of NFTs first</p>, resulting in a proportional quantity of tokens in the wallet (minting one NFT equals receiving <span>1 $RECAT</span> airdropped into your account). This inverse functionality enhances user convenience.
                </div>
                <div class="section">CONCLUSION</div>
                <div class="about-text">
                    As the NFT landscape continues to evolve, the choice between ERC-404 and its alternatives will likely depend on how effectively these standards adapt to user needs, scalability concerns, and the overall dynamics of the decentralized marketplace. The path forward for NFT collections rests at this crossroads, with stakeholders closely monitoring the development and adoption of these standards to shape the future of the NFT ecosystem.
                </div>
                <div class="rarity" id="rarity">
                    <div class="about-title">RARITY</div>
                    <div class="rarity-items">
                        <div class="rarity-item">
                            <div class="rarity-item__title"><p>COMMON,<br />69 / 100</p></div>
                            <div class="rarity-item__img">
                                <img src="img/green-cat.svg" alt="Green Cat" />
                            </div>
                        </div>
                        <div class="rarity-item">
                            <div class="rarity-item__title">
                                <p>NOT SO COMMON,<br />18 / 100</p>
                            </div>
                            <div class="rarity-item__img">
                                <img src="img/blue-cat.svg" alt="Blue Cat" />
                            </div>
                        </div>
                        <div class="rarity-item">
                            <div class="rarity-item__title">
                                <p>NOT COMMON, <br />10 / 100</p>
                            </div>
                            <div class="rarity-item__img">
                                <img src="img/orange-cat.svg" alt="Orange Cat" />
                            </div>
                        </div>
                        <div class="rarity-item">
                            <div class="rarity-item__title">
                                <p>legendary, <br />3 / 100</p>
                            </div>
                            <div class="rarity-item__img">
                                <img src="img/pink-cat.svg" alt="Pink Cat" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <p>2024 (c) RECAT</p>
                </div>
            </div>


            <Popup active={OnSuccessPopup} setActive={setOnSuccessPopup} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>Minting complete! It's time to share some positive words on X.</p>
                    </div>
                    <div className="popup-btn-box">
                        <a href={`https://etherscan.io/tx/${mintData?.hash}`} className='mint-btn' style={{}} target="_blank" rel="noreferrer">
                            <p className="unselectable">Etherscan</p>
                        </a>
                    </div>
                </div>
            </Popup>
            <Popup active={UserRejected} setActive={setUserRejected} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>You declined the tx. </p>
                    </div>
                </div>
            </Popup>
            <Popup active={MaxMint} setActive={setMaxMint} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p> You can only mint up to {Number(isContract.USER_MINT_SUPPLY - isContract.mintCount)} NFTs. It looks like you're trying to exceed that limit.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={NotFUNDS} setActive={setNotFUNDS}>
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>Insufficient balance.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={ChainError} setActive={setChainError}>
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>Wrong chain. Please switch to the mainnet.</p>
                    </div>
                </div>
            </Popup>

        </>
    );
}

export default App;
