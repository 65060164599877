import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@rainbow-me/rainbowkit/styles.css';
import {
    getDefaultWallets,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig, } from 'wagmi';
import { mainnet, goerli } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public';
import '../src/style.css'
const { chains, provider } = configureChains(
    [mainnet, goerli],
    [
        alchemyProvider({ apiKey: process.env.REACT_APP_API_KEY }),
        infuraProvider({ apiKey: process.env.REACT_APP_INFURA_API_KEY }),
        publicProvider()
    ]
);

const { connectors } = getDefaultWallets({
    appName: 'NftProject',
    chains
});

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} modalSize="compact">
            <React.StrictMode>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </React.StrictMode>
        </RainbowKitProvider>
    </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
